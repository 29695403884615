.chat-page {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
}

.ChatApp {
  flex: 1;
}


.chat_loading {

  animation:rotate 1s infinite linear;

}

@keyframes rotate {
  0% {transform: rotate(360deg); background-color: aliceblue;}
}