.register_page{
    background-image: url('../../Assets/TVYTbAXWheQpRcWDaDMu.svg');
    background-color: #f0f2f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.register-form_header .title{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
}

.register-form_header .title .logo{
    width: 48px;
}

.register-form_header .slogan{
    font-size: 24px;
}


.register-form_header .system-name{
    color: rgba(0,0,0,.85);
    font-weight: 600;
    font-size: 33px;
}

.register_form{
    width: 390px;
}

.register-form-button{
    width: 100%;
    margin-bottom: 16px;
}