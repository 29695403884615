.chat_list_container {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
.chat_list {
  padding: 16px;
}

.documnet_list_header {
  padding: 8px 16px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: -2px 2px 5px #d3dada;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.chat_list_body {
  min-height: calc(100% - 48px - 42px - 16px);
}
.chat_list_body__empty {
  min-height: calc(100% - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-ui_container .chatui_empty {
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.chatui_empty .Empty-img {
  display: none;
}

.quick-operstion_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
}

.quick-operstion_card {
  display: flex;
  background-color: #f7f8fa;
  padding: 8px;
  border-radius: 8px;
  flex-grow: 1;
  cursor: pointer;
}
.quick-operstion_content {
  flex-grow: 1;
}

.quick-operstion_content .quick-operstion_header {
  font-weight: bold;
  text-align: left;
  margin-bottom: 4px;
}

.quick-operstion_content .quick-operstion_header img {
  transition: 0.3s;
}

.quick-operstion_content:hover .quick-operstion_header img {
  animation: quick-operstion_content_animation 0.8s;
}

@keyframes quick-operstion_content_animation {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.quick-operstion_content .quick-operstion_body {
  text-align: left;
  font-size: 12px;
  color: #5e5e5e;
}

.quick-operstion_sider__right {
  display: flex;
  align-items: center;
  color: rgb(90, 90, 90);
}

.empty_header {
  margin-bottom: 64px;
}

.empty_header .title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty_header .title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  margin-bottom: 32px;
}

.empty_header .title .logo {
  width: 48px;
}

.empty_header .slogan {
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  background: linear-gradient(90deg, #0b35f6 0%, #2acaff 49%, #ab13ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chat-list_footer {
  padding: 0 16px;
}
