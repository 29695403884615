.document-render {
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
  width: 100%;
}

.document-name_header {
  padding: 0 16px;
  font-size: 32px;
  border-bottom: #ccc 1px solid;
}

.document-content {
  width: 100%;
  height: calc(100% - 45px);
  overflow: auto;
  word-break: break-all;
}

.document-content_emty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin-nested-loading {
  position: static;
  height: 100%;
}
.ant-spin-container {
  height: 100%;
}
